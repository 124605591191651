import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="border-b border-gray-200 dark:border-gray-700 py-4">
            <div
                onClick={toggleOpen}
                className="flex justify-between items-center cursor-pointer"
            >
                <h4 className="text-xl font-oswald dark:text-gray-100">
                    {question}
                </h4>
                <span className="text-gray-500 dark:text-gray-400">
                    {isOpen ? "-" : "+"}
                </span>
            </div>
            {isOpen && (
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <p className="mt-2 text-gray-700 dark:text-gray-300">
                        {answer}
                    </p>
                </motion.div>
            )}
        </div>
    );
};

const FAQ = ({ faqs }) => {
    const { t } = useTranslation();
    return (
            <div className="max-w-2xl font-oswald mx-auto p-4 sm:p-6 lg:p-8">
                <h2 className="text-3xl text-blue-900 text-center font-varela dark:text-gray-100 mb-6">
                    {t('faq')}
                </h2>
                {faqs.map((faq, index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
    );
};

export default FAQ;
