import React, { useEffect, useState } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Fade } from 'react-awesome-reveal';

const SocialLinks = () => {
    const [isHidden, setIsHidden] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleScroll = () => {
        const heroHeight = document.getElementById('hero')?.offsetHeight || 0;
        const scrollTop = window.scrollY;

        if (scrollTop > heroHeight) {
            setIsHidden(true);
        } else {
            setIsHidden(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const socialLinks = [
        {
            href: 'https://www.facebook.com/profile.php?id=61561101796351',
            icon: <FaFacebookF />,
            alt: 'Facebook',
            color: 'text-blue-800 dark:text-neutral-300', // Modrá barva pro Facebook
            hoverColor: 'hover:text-blue-500 dark:hover:text-white', // Tmavší modrá barva při hover
        },
        {
            href: 'https://www.instagram.com/littleclub_ostrava',
            icon: <FaInstagram />,
            alt: 'Instagram',
            color: 'text-pink-700 dark:text-neutral-300', // Růžová barva pro Instagram
            hoverColor: 'hover:text-pink-400 dark:hover:text-white', // Tmavší růžová barva při hover
        },
        // Přidejte další odkazy, pokud je potřeba
    ];

    return (
        <div
            className="relative flex flex-col space-y-4"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {/* Neviditelný rámeček - pokud je infobar zapnuty tam top-60 ... pokud neni tak top-20*/}
            <div
                className={`fixed top-60 right-[5px] w-16 h-36 transition-opacity duration-300 ${isHidden ? 'opacity-100' : 'opacity-0'}`}
                style={{ background: 'transparent' }}
            ></div>
            {/* Tlacitka - pokud je infobar zapnuty tam top-64 ... pokud neni tak top-24*/}
            <div
                className={`fixed top-64 right-4 flex flex-col space-y-4 transition-transform duration-300 ${isHidden ? (isHovered ? 'translate-x-0' : 'translate-x-full') : 'translate-x-0'}`}
            >
                {socialLinks.map((link, index) => (
                    <Fade
                        key={index}
                        direction="right"
                        duration={1000}
                        delay={index * 200} // Nastavení zpoždění mezi jednotlivými animacemi
                    >
                        <a
                            href={link.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`bg-white dark:bg-gray-600 dark:border-gray-600 border border-gray-100 p-4 rounded-full shadow-lg flex items-center justify-center transition-colors duration-300 ${link.color} ${link.hoverColor}`}
                            aria-label={link.alt}
                            style={{ width: '50px', height: '50px' }}
                        >
                            {link.icon}
                        </a>
                    </Fade>
                ))}
            </div>
        </div>
    );
};

export default SocialLinks;
