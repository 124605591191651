import React from "react";

const Title = ({title, titleColor, from, to}) => {
    return (
        <div
            className={`bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] ${from} from-0% ${to} to-70% py-16 pt-24 dark:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] dark:from-blue-900 dark:to-gray-900`}>
            <h2 className={`text-4xl ${titleColor} font-bold mb-8 text-center font-varela dark:text-white`}>
                {title}
            </h2>
        </div>
    )
}

export default Title;
