import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSlider = () => {
    const { t } = useTranslation();
    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,  // Disable arrows
        fade: true,  // Enable fade effect for smoother transitions
        cssEase: 'linear'
    };

    const testimonials = [
        {
            text: t('testamontial0'),
            author: t('author0')
        },
        {
            text: t('testamontial1'),
            author: t('author1')
        },
        {
            text: t('testamontial2'),
            author: t('author2')
        },
        {
            text: t('testamontial3'),
            author: t('author3')
        }
    ];

    return (
        <div id="references" className="testimonial-slider bg-gradient-to-b from-white to-amber-100 dark:from-gray-900 dark:to-blue-900 py-16 pt-24">
            <h2 className="text-4xl text-blue-900 font-bold mb-8 text-center font-varela dark:text-white">
                {t('testamontialTitle')}
            </h2>
            <div className="max-w-4xl mx-auto">
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="px-4">
                            <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg text-center">
                                <p className="text-xl font-varela text-gray-800 dark:text-white mb-4">
                                    {testimonial.text}
                                </p>
                                <p className="text-lg font-semibold font-gluten text-blue-900 dark:text-white">
                                    - {testimonial.author}
                                </p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};


export default TestimonialSlider;
