"use client";
import React, { useEffect, useRef, useState } from "react";
import { createNoise3D } from "simplex-noise";
import { cn } from "../utils/cn"; // Adjust the path as necessary

const WavyBackground = ({
                            children,
                            className,
                            containerClassName,
                            colors,
                            waveWidth,
                            blur = 10,
                            speed = "slow",
                            waveOpacity = 0.25,
                            ...props
                        }) => {
    const noise = createNoise3D();
    let w, h, nt, i, x, ctx, canvas;
    const canvasRef = useRef(null);

    const getSpeed = () => {
        switch (speed) {
            case "slow":
                return 0.0005;
            case "fast":
                return 0.002;
            default:
                return 0.001;
        }
    };

    const init = () => {
        canvas = canvasRef.current;
        ctx = canvas.getContext("2d");
        w = ctx.canvas.width = window.innerWidth;
        h = ctx.canvas.height = window.innerHeight;
        ctx.filter = `blur(${blur}px)`;
        nt = 0;
        window.onresize = function () {
            w = ctx.canvas.width = window.innerWidth;
            h = ctx.canvas.height = window.innerHeight;
            ctx.filter = `blur(${blur}px)`;
        };
        render();
    };

    const waveColors = colors || ["#38bdf8", "#818cf8", "#c084fc", "#e879f9", "#22d3ee"];
    const drawWave = (n) => {
        nt += getSpeed();
        for (i = 0; i < n; i++) {
            ctx.beginPath();
            ctx.lineWidth = waveWidth || 50;
            ctx.strokeStyle = waveColors[i % waveColors.length];
            for (x = 0; x < w; x += 5) {
                const y = noise(x / 800, 0.3 * i, nt) * 100;
                ctx.lineTo(x, y + h * 0.5);
            }
            ctx.stroke();
            ctx.closePath();
        }
    };

    let animationId;
    const render = () => {
        ctx.globalAlpha = waveOpacity || 0.5;
        ctx.clearRect(0, 0, w, h);
        drawWave(5);
        animationId = requestAnimationFrame(render);
    };

    useEffect(() => {
        init();
        return () => cancelAnimationFrame(animationId);
    }, []);

    const [isSafari, setIsSafari] = useState(false);
    useEffect(() => {
        setIsSafari(
            typeof window !== "undefined" &&
            navigator.userAgent.includes("Safari") &&
            !navigator.userAgent.includes("Chrome")
        );
    }, []);

    return (
        <div className={cn("h-screen flex flex-col items-center justify-center bg-gradient-to-b from-white to-sky-200 dark:from-gray-900 dark:to-blue-900", containerClassName)}>
            <canvas
                className="absolute inset-0 z-0 lg:pt-[420px] pt-[280px]"
                ref={canvasRef}
                id="canvas"
                style={{ ...(isSafari ? { filter: `blur(${blur}px)` } : {}) }}
            ></canvas>
            <div className={cn("relative", className)} {...props}>
                {children}
            </div>
        </div>
    );
};

export default WavyBackground;
