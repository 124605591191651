import QRCode from "qrcode.react";
import { Zoom, Fade} from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBitcoin} from "@fortawesome/free-brands-svg-icons";
import React , { useState } from "react";

const PaymentQRcode = ({ course, amount, variableSymbol, message }) => {

    const [showEmail, setShowEmail] = useState(false);

    const handleClick = () => {
        setShowEmail(!showEmail);
    };

    var account = ""

    const currency = "CZK";

    console.log(course)
    if (course === "LittleSwim") {
        account = "CZ4655000000001195384511+RZBCCZPP";
    }
    else if (course === "LittleNinjas") {
        account = "CZ7655000000007970238003+RZBCCZPP";
    }

    console.log(account)
    console.log(amount)
    console.log(variableSymbol)
    console.log(message)

    const qrData = `SPD*1.0*ACC:${account}*AM:${amount}*CC:${currency}*X-VS:${variableSymbol}*MSG:${message}`;

    return (
        <Zoom triggerOnce={true}>
        <div className="flex flex-col items-center mx-2 font-varela">
            <h2 className="text-2xl font-bold text-center mb-4 uppercase dark:text-white">Děkujeme za rezervaci</h2>
            <span className="text-sm text-gray-400"> id: {variableSymbol}</span>
            <p className="mt-4 dark:text-white text-center text-xl font-oswald">Platební údaje byly úspěšně odeslány na Váš e-mail. Pro rychlejší platbu můžete využít QR kód.</p>
            <p className="mt-6 mb-4 dark:text-white text-center text-sm">Naskenujte QR kód pro provedení platby.</p>
            <QRCode className="dark:border-2" value={qrData} size={256}/>
            <p className="mt-4 text-sm text-gray-500 text-center dark:text-white">QR kód obsahuje všechny potřebné platební údaje.</p>
            <p className="cursor-pointer mt-4 font-bold text-xs text-center border-1 bg-amber-500 hover:bg-amber-200 text-black rounded shadow-xl p-2 flex items-center justify-center gap-2 uppercase"
               onClick={handleClick}>
                Platba
                <FontAwesomeIcon icon={faBitcoin} className="w-7 h-7"/>
            </p>
            {showEmail && (
                <Fade>
                <div
                    className="font-oswald mt-1 text-sm text-gray-700 dark:text-white border-1 rounded shadow-xl p-3 transition-opacity duration-500 ease-in-out text-center"
                    style={{ opacity: showEmail ? 1 : 0 }}
                >
                    Platba pouze po dohodě. Prosím napište na <a href="mailto:littleclubostrava@gmail.com" className="hover:underline hover:text-amber-500">littleclubostrava@gmail.com</a>
                </div>
                </Fade>
            )}
        </div>
        </Zoom>
    );
};

export default PaymentQRcode;
