import React from 'react';
import PropTypes from 'prop-types';

const Divider = ({ fromColor, toColor }) => {
  return (
      <div
          className={`h-24 bg-gradient-to-b ${fromColor} ${toColor} dark:bg-gradient-to-b dark:from-blue-900 dark:to-dark-900`}
      />
  );
};

Divider.propTypes = {
  fromColor: PropTypes.string.isRequired,
  toColor: PropTypes.string.isRequired,
};

export default Divider;
