import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faBuilding, faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import DragCloseDrawer from "./DragCloseDrawer";
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const {t} = useTranslation();
    const [showModalIssue, setShowModalIssue] = useState(false);
    const [showModalRules, setShowModalRules] = useState(false);
    const currentYear = () => {
        return new Date().getFullYear();
    };
    return (
        /*<footer className="bg-blue-950 dark:bg-gray-800 text-gray-300 py-10" style={{
            boxShadow: '0 0px 150px rgba(0, 200, 240, 1.3)' // Přidání stínu nad patičku
        }}>*/
    <footer className="bg-blue-950 dark:bg-gray-800 text-gray-300 py-10">
        <div className="container mx-auto px-6 lg:px-8">
            <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start gap-8">
                {/* Logo a krátký popis */}
                <div className="flex flex-col items-center lg:items-start">
                    <h1 className="text-3xl font-bold text-white mb-4">
                        <span className="font-gluten text-white dark:text-white">Little</span>
                        <span
                            className="font-kalam text-4xl transform bg-gradient-to-r from-sky-300 to-sky-500 inline-block text-transparent bg-clip-text dark:bg-gradient-to-r dark:from-blue-400 dark:to-blue-600"
                            style={{display: 'inline-block', transform: 'skewY(-10deg)'}}>
                                Club
                            </span>
                    </h1>
                    <p className="text-center lg:text-justify">
                        {t('footerTitle')}
                    </p>
                    <h2 className="text-xl font-bold text-white mb-2 mt-5">{t('footerFollow')}</h2>
                    <div className="flex space-x-4">
                        <a href="https://www.facebook.com/profile.php?id=61561101796351"
                           className="text-blue-500 hover:text-white">
                            <FontAwesomeIcon icon={faFacebook} className="w-[25px] h-[25px]"/>
                        </a>
                        <a href="https://www.instagram.com/littleclub_ostrava"
                           className="text-blue-500 hover:text-white">
                            <FontAwesomeIcon icon={faInstagram} className="w-7 h-7"/>
                        </a>
                    </div>
                </div>

                {/* Navigační odkazy */}
                <div className="flex flex-col lg:flex-row gap-10 w-full lg:w-auto lg:pl-8 lg:pt-12">
                    <div className="flex flex-col items-center lg:items-start w-full lg:w-36">
                        <h2 className="text-xl font-bold text-white mb-2">{t('footerInfo')}</h2>
                        <ul className="space-y-2">
                            <li>
                                <a onClick={() => setShowModalIssue(true)}
                                   className="hover:text-white text-sm cursor-pointer uppercase">
                                    {t('footerInfoBtn0')}
                                </a>
                            </li>
                            <li>
                                <a onClick={() => setShowModalRules(true)}
                                   className="hover:text-white text-sm cursor-pointer uppercase">
                                    {t('footerInfoBtn1')}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <DragCloseDrawer open={showModalIssue} setOpen={setShowModalIssue}>
                        <div className="mx-auto max-w-2xl space-y-6 text-center text-neutral-200 p-4 sm:p-6 lg:p-8">
                            <h2 className="text-4xl font-bold text-white mb-6">{t('footerInfoBtn0')}</h2>
                            <p className="text-lg">
                                V případě, že se na lekci nemůžete dostavit, pište prosím SMS nejpozději
                                do <strong>17:00</strong> předchozího dne, na číslo <strong>774 300 181 </strong>
                                (prosím o omluvu jméno dítěte, datum lekce, čas lekce). Pokud lekce není omluvena včas,
                                nestihneme zajistit náhradu a vaše lekce tak zbytečně propadá, to netěší vás ani nás.
                                Lekci prosím omluvte i když víte, že jste limit prošvihli, ať na vás u vody zbytečně
                                nečekáme.
                            </p>
                            <p className="text-lg">
                                Nahradit jdou lekce jen řádně omluvené a v průběhu konání kurzu (na plavání tedy
                                přijdete dvakrát i vícekrát v týdnu). Pokud vám ke konci kurzu zbydou náhradní lekce,
                                využít jdou pouze souběžně s následujícím kurzem. V případě, že již dál nepokračujete,
                                lekce propadají.
                            </p>
                        </div>
                    </DragCloseDrawer>

                    <DragCloseDrawer open={showModalRules} setOpen={setShowModalRules}>
                        <div className="mx-auto max-w-2xl space-y-6 text-neutral-200 p-4 sm:p-6 lg:p-8">
                            <h2 className="text-4xl font-bold text-white mb-6 text-center">{t('footerInfoBtn1')}</h2>
                            <div>
                                <h3 className="text-2xl font-semibold text-white mb-2">Základní pravidla kurzů:</h3>
                                <ul className="space-y-2">
                                    <li><strong>1.</strong> Prohlašuji, že mé dítě je zdravé a může na základě vyjádření
                                        svého ošetřujícího pediatra navštěvovat kurzy organizovaného plavání kojenců a
                                        batolat nebo kurzy cvičení pro děti.
                                    </li>
                                    <li><strong>2.</strong> Děti do 3 let musí na lekce plavání vstupovat do bazénu
                                        pouze se speciální plavací plínou a/nebo kojeneckými neoprenovými plavkami.
                                    </li>
                                    <li><strong>3.</strong> V případě, že se na lekci nemůžete dostavit, napište nám
                                        prosím SMS zprávu nejpozději do <strong>17:00</strong> předchozího dne před dnem
                                        konání lekce, a to na tel. <strong>774 300 181</strong> (prosím o uvedení jména
                                        dítěte, datum a čas lekce). Pokud lekce není omluvena včas, vaše lekce tak
                                        zbytečně propadá. Lekci prosím omluvte, i když víte, že jste limit prošvihli, ať
                                        na vás zbytečně nečekáme. Nahradit je možné <strong>3 lekce</strong>, a to pouze
                                        řádně omluvené. Na náhradu budete mít měsíc po ukončení kurzu. V případě, že
                                        budete pokračovat v dalším kurzu, jeden měsíc pro splnění náhrad se začne
                                        počítat až po ukončení posledního kurzu.
                                    </li>
                                    <li><strong>4.</strong> Účastníci kurzu jsou povinní respektovat a dodržovat
                                        návštěvní řád daného zařízení, které navštěvují. Dále jsou povinni dbát pokynů
                                        dané lektorky.
                                    </li>
                                    <li><strong>5.</strong> Lekce přes týden trvají <strong>30 min
                                        (plavání)</strong> nebo <strong>40 min (Little Ninjas)</strong>. Víkendové lekce
                                        plavání trvají <strong>40 min</strong>. Lekce pro děti od 3 let trvají <strong>60
                                            min</strong>.
                                    </li>
                                    <li><strong>6.</strong> Při výuce je možné fotit a natáčet vlastní děti se souhlasem
                                        lektorky.
                                    </li>
                                    <li><strong>7.</strong> Na lekce plavání přes týden má přístup s dítětem jeden
                                        rodič; na víkendové lekce plavání je možný přístup obou rodičů. Lekce pro děti
                                        od 3 do 6 let probíhají bez rodičů. Na lekce Little Ninjas má přístup s dítětem
                                        jeden rodič.
                                    </li>
                                    <li><strong>8.</strong> V případě, že lekce neproběhne z technických nebo
                                        organizačních důvodů na straně provozovatele, bude stanoven náhradní termín.
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h3 className="text-2xl font-semibold text-white mb-2">Hygienická a bezpečnostní
                                    pravidla:</h3>
                                <ul className="space-y-2">
                                    <li><strong>1.</strong> Na lekci je zakázán vstup dětí a rodičů s akutním nebo
                                        infekčním onemocněním. Provozovatel může z lekce vyloučit dítě nebo rodiče,
                                        pokud je zjevné, že dítě nebo rodič výše uvedenými problémy vykazuje (kašel,
                                        rýma, viditelné kožní problémy, apod.). Jde nám zejména o to, abychom zajistili
                                        bezpečné prostředí pro všechny na kurzu.
                                    </li>
                                    <li><strong>2.</strong> Doporučujeme, aby děti alespoň 30 min před lekcí nejedly.
                                    </li>
                                    <li><strong>3.</strong> Při pohybu ve všech prostorách provozovatele je nutné dbát
                                        zvýšené opatrnosti s ohledem na kluzký povrch, především v prostorách bazénu,
                                        sprch a saun.
                                    </li>
                                    <li><strong>4.</strong> Do prostor šaten, sprch, saun a bazénu je zákaz nosit
                                        předměty ze skla a jídlo.
                                    </li>
                                    <li><strong>5.</strong> V zájmu udržení kvality bazénové vody je každý účastník
                                        lekce povinen se před vstupem do bazénu osprchovat včetně dítěte. Po osprchování
                                        dítěte navlékneme dítěti plavky.
                                    </li>
                                    <li><strong>6.</strong> Účastníci nesmí nosit do vody řetízky, hodinky, velké
                                        náušnice, prstýnky a jiné šperky.
                                    </li>
                                    <li><strong>7.</strong> V případě, že budete mít u sebe cennosti, oznamte to
                                        lektorovi, který vám tyto cennosti schová na k tomu určené místo a po skončení
                                        lekce vám je opět předá zpět.
                                    </li>
                                    <li><strong>8.</strong> Za bezpečnost dítěte v prostorách bazénu i ve vodě plně
                                        odpovídá rodič nebo jeho doprovod.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </DragCloseDrawer>

                    {/* Kontaktní informace */}
                    <div className="flex flex-col items-center lg:items-start lg:ml-10 w-full lg:w-80">
                        <h2 className="text-xl font-bold text-white mb-2 text-center lg:text-left">{t('footerContact')}</h2>
                        <div className="flex flex-col lg:flex-row justify-center lg:justify-start">
                            <div className="lg:w-1/2 text-center lg:text-left">
                                <h3 className="text-lg font-gluten text-white mb-2">LittleSwim</h3>
                                <ul className="space-y-2">
                                    <li className="flex items-center justify-center lg:justify-start">
                                        <FontAwesomeIcon icon={faUser}
                                                         className="w-4 h-4 text-blue-500 dark:text-blue-400 mr-3"/>
                                        <span className="font-varela text-sm whitespace-nowrap">Jana Tioková</span>
                                    </li>
                                    <li className="flex items-center justify-center lg:justify-start">
                                        <FontAwesomeIcon icon={faBuilding}
                                                         className="w-4 h-4 text-blue-500 dark:text-blue-400 mr-3"/>
                                        <span className="font-varela text-sm whitespace-nowrap">IČ: 11953845</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-1/2 lg:pl-4 mt-2 sm:mt-0 text-center lg:text-left">
                                <h3 className="text-lg font-gluten text-white mb-2 mt-2 sm:mt-0">LittleNinjas</h3>
                                <ul className="space-y-2">
                                    <li className="flex items-center justify-center lg:justify-start">
                                        <FontAwesomeIcon icon={faUser}
                                                         className="w-4 h-4 text-blue-500 dark:text-blue-400 mr-3"/>
                                        <span className="font-varela text-sm whitespace-nowrap">Markéta Lokajová</span>
                                    </li>
                                    <li className="flex items-center justify-center lg:justify-start">
                                        <FontAwesomeIcon icon={faBuilding}
                                                         className="w-4 h-4 text-blue-500 dark:text-blue-400 mr-3"/>
                                        <span className="font-varela text-sm whitespace-nowrap">IČ: 21877319</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="items-center justify-center mt-5 text-center lg:text-left">
                            <p>
                                <FontAwesomeIcon icon={faPhone}
                                                 className="w-4 h-4 text-blue-500 dark:text-blue-400 mr-3"/>
                                <a href="tel:+420774300181"
                                   className="font-varela text-sm text-white whitespace-nowrap">+420 774 300 181</a>
                            </p>
                            <p className="mt-1">
                                <FontAwesomeIcon icon={faEnvelope}
                                                 className="w-4 h-4 text-blue-500 dark:text-blue-400 mr-3"/>
                                <a href="mailto:littleclubostrava@gmail.com"
                                   className="font-varela text-sm text-white whitespace-nowrap">littleclubostrava@gmail.com</a>
                            </p>
                        </div>
                    </div>


                </div>
            </div>

            {/* Copyright */}
            <div className="mt-8 text-center border-t border-gray-700 pt-4">
                <p>&copy; {currentYear()} <a className="cursor-pointer hover:text-amber-500"
                                             href="https://www.daviko.cz">DAVIKO IT solutions</a>. {t('footerLaw')}.</p>
            </div>
        </div>
    </footer>
)
    ;
};

export default Footer;
