import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShowBanner(false);
    };

    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'false');
        setShowBanner(false);
    };

    const toggleMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };

    if (!showBanner) return null;

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 md:p-6 flex flex-col items-center md:items-start z-50">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between w-full">
                <p className="text-center md:text-left mb-2 md:mb-0 md:flex-1">
                    Tento web používá cookies, aby vám zajistil co nejlepší zážitek při návštěvě našich webových stránek. Některé cookies jsou nezbytné pro správné fungování webu, jiné nám pomáhají vylepšovat služby a zobrazovat relevantní obsah.
                    <button onClick={toggleMoreInfo} className="underline ml-2 hover:text-blue-300">
                        {showMoreInfo ? 'Méně informací' : 'Více informací'}
                    </button>
                </p>
                <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-4 md:mt-0 md:ml-4">
                    <button
                        onClick={handleAccept}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Souhlasím
                    </button>
                    <button
                        onClick={handleDecline}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Nesouhlasím
                    </button>
                </div>
            </div>
            {showMoreInfo && (
                <div className="text-left mt-2 bg-gray-700 p-4 rounded w-full">
                    <h2 className="text-lg font-bold mb-2 underline">Informace o používání cookies</h2>
                    <p>Tento web používá pouze základní cookies pro správné fungování webu, analytických cookies pro zlepšení našich služeb a zobrazení relevantních informací.</p>
                    {/*<h3 className="text-md font-semibold mt-2">Typy cookies</h3>
                    <ul className="list-disc list-inside">
                        <li><strong>Nezbytné cookies:</strong> Tyto cookies jsou nutné pro správné fungování webu a nelze je vypnout v našich systémech.</li>
                        <li><strong>Analytické cookies:</strong> Tyto cookies nám pomáhají sledovat chování uživatelů na našem webu, abychom mohli vylepšovat naše služby.</li>
                    </ul>*/}
                    <h3 className="text-md font-semibold mt-2">Správa cookies</h3>
                    <p>Uživatelé mohou spravovat nebo smazat cookies prostřednictvím nastavení svého prohlížeče. Další informace naleznete v nápovědě svého prohlížeče.</p>
                </div>
            )}
        </div>
    );
};

export default CookieConsent;
