import { Fade as Hamburger } from "hamburger-react";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useTranslation } from 'react-i18next';

const links = [
    { name: "home", to: "#home", id: 1 },
    { name: "lessons", to: "#lessons", id: 2 },
    { name: "references", to: "#references", id: 3 },
    { name: "contact", to: "#reservation", id: 4 }
];

const itemVariants = {
    closed: {
        opacity: 0,
    },
    open: { opacity: 1 }
};

const sideVariants = {
    closed: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    open: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: 1
        }
    }
};

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);
    const [nav, setNav] = useState(false);
    const [theme, setTheme] = useState("light");
    const [darkModeStatus, setDarkModeStatus] = useState(false);
    const [top, setTop] = useState(true);

    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
            localStorage.setItem("theme", theme);
        } else {
            document.documentElement.classList.remove("dark");
            localStorage.setItem("theme", theme);
        }
    }, [theme]);

    useEffect(() => {
        const scrollHandler = () => {
            window.scrollY > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    const handleThemeSwitch = () => {
        setTheme(theme === "dark" ? "light" : "dark");
        setDarkModeStatus(!darkModeStatus);
    };

    const handleNav = () => {
        setNav(!nav);
        setOpen(!isOpen);
    };

    const navbarVariants = {
        initial: { height: '60px' },
        animate: { height: top ? '60px' : '80px', transition: { duration: 0.6 } },
    };

    return (
        <motion.nav
            initial="initial"
            animate="animate"
            variants={navbarVariants}
            className={`fixed left-0 w-full bg-opacity-95 bg-transparent dark:bg-[#121B30] dark:bg-opacity-80 z-10 ${!top && `bg-white bg-gradient-to-b top-0 drop-shadow-lg dark:bg-gradient-to-b dark:from-gray-900 dark:to-gray-900'`}`}
        >
            <div className="flex items-center justify-between h-20 max-w-[1240px] px-4 mx-auto text-black">
                <motion.div className="grid">
                    <div
                        className="text-4xl font-bold text-blue-900 font-gluten -mb-3.5 select-none cursor-pointer dark:text-white"
                        style={{ display: 'inline' }}>
                        Little
                        <motion.span
                            className="text-2xl font-kalam bg-gradient-to-r from-sky-400 to-sky-600 bg-clip-text text-transparent dark:bg-gradient-to-r dark:from-blue-400 dark:to-blue-600"
                            initial={{
                                opacity: 0,
                                scale: 1,
                                skewY: "0deg",
                            }}
                            animate={{
                                opacity: 1,
                                scale: 1,
                                skewY: "-10deg",
                            }}
                            transition={{
                                delay: 0.4,
                                duration: 0.8,
                            }}
                            style={{ display: 'inline-block' }}
                        >
                            Club
                        </motion.span>
                    </div>
                </motion.div>

                <ul className="hidden md:flex space-x-12 select-none cursor-pointer uppercase font-varela dark:text-white">
                    {links.map(({ name, to, id }) => (
                        <motion.a whileHover={{ scale: 1.1 }} href={to} key={id}>
                            <li className="hover:text-sky-400">{t(name)}</li>
                        </motion.a>
                    ))}
                </ul>
                <div className='hidden md:flex items-center space-x-4 pr-8'>
                    <DarkModeSwitch
                        checked={darkModeStatus}
                        onChange={handleThemeSwitch}
                        size={24}
                        sunColor="black"
                        moonColor="white"
                    />
                    <div>
                        <AnimatePresence mode="wait">
                            {currentLanguage === 'en' ? (
                                <motion.button
                                    key="cz"
                                    onClick={() => changeLanguage('cz')}
                                    className="mr-2 dark:text-amber-50 font-varela"
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    CZ
                                </motion.button>
                            ) : (
                                <motion.button
                                    key="en"
                                    onClick={() => changeLanguage('en')}
                                    className="mr-2 dark:text-amber-50 font-varela"
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    EN
                                </motion.button>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
                <div onClick={handleNav} className="mr-4 block md:hidden dark:text-white z-10">
                    <Hamburger toggled={isOpen} toggle={setOpen} />
                </div>
            </div>

            <AnimatePresence>
                {isOpen && (
                    <motion.aside
                        className="bg-stone-50 opacity-95 dark:bg-gray-800 h-[100vh] w-[100vw] fixed top-0 left-0 z-0 flex flex-col"
                        initial={{ width: 0 }} //opacity : 0
                        animate={{ width: "100vw" }} //opacity : 1
                        exit={{ width: 0, transition: { delay: 0.1, duration: 0.9 } }} //opacity : 0 misto width: 0
                    >
                        <motion.div
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={sideVariants}
                            className="flex-grow"
                        >
                            <div className="flex flex-col h-full justify-center items-center space-y-6 mt-12">
                                {links.map(({ name, to, id }) => (
                                    <motion.a
                                        onClick={handleNav}
                                        className="text-black font-oswald uppercase p-4 font-semibold text-3xl block text-center dark:text-stone-400 hover:text-sky-400 dark:hover:text-white"
                                        key={id}
                                        href={to}
                                        whileHover={{ scale: 1.1 }}
                                        variants={itemVariants}
                                    >
                                        {t(name)}
                                    </motion.a>
                                ))}
                            </div>
                        </motion.div>

                        <motion.div
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={itemVariants}
                            className="p-4 flex flex-col items-center space-y-4 mb-24">
                            <DarkModeSwitch
                                checked={darkModeStatus}
                                onChange={handleThemeSwitch}
                                size={24}
                                sunColor="black"
                                moonColor="white"
                            />
                            <div className="flex space-x-4">
                                <AnimatePresence mode="wait">
                                    {currentLanguage === 'en' ? (
                                        <motion.button
                                            key="cz"
                                            onClick={() => changeLanguage('cz')}
                                            className="dark:text-amber-50 font-varela"
                                            initial={{opacity: 0, y: -10}}
                                            animate={{opacity: 1, y: 0}}
                                            exit={{opacity: 0, y: 10}}
                                            transition={{duration: 0.2}}
                                        >
                                            CZ
                                        </motion.button>
                                    ) : (
                                        <motion.button
                                            key="en"
                                            onClick={() => changeLanguage('en')}
                                            className="dark:text-amber-50 font-varela"
                                            initial={{opacity: 0, y: -10}}
                                            animate={{opacity: 1, y: 0}}
                                            exit={{opacity: 0, y: 10}}
                                            transition={{duration: 0.2}}
                                        >
                                            EN
                                        </motion.button>
                                    )}
                                </AnimatePresence>
                            </div>
                        </motion.div>

                    </motion.aside>
                )}
            </AnimatePresence>
        </motion.nav>
    );
};

export default Navbar;
