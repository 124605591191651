import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Zoom, Slide } from "react-awesome-reveal";
import { FaCheckCircle, FaFilePdf } from 'react-icons/fa';
import bg1 from "../img/1.webp";
import bg2 from "../img/2.webp";
import bg3 from "../img/3.webp";
import bg4 from "../img/bg4.webp";
import bg5 from "../img/n1.webp";
import bg6 from "../img/n2.webp";
import bg7 from "../img/n3.webp";
import bg8 from "../img/n4.webp";
import hippo2 from "../img/hippo2.webp";
import ninja from "../img/tucnak1.webp";
import swimRules from "../assets/SwimRules.pdf"
import ninjasRules from "../assets/NinjasRules.pdf"
import FAQ from "./FAQ";
import Title from "./Title";

const Services = () => {
    const { t } = useTranslation();
    const [flipped, setFlipped] = useState(Array(8).fill(false));

    const openSwimPdf = () => {
        window.open(swimRules, '_blank');
    };

    const openNinjasPdf = () => {
        window.open(ninjasRules, '_blank');
    };

    const handleFlip = (index) => {
        setFlipped((prevFlipped) => {
            const newFlipped = [...prevFlipped];
            newFlipped[index] = !newFlipped[index];
            return newFlipped;
        });
    };

    const faqs = [
        {
            question: t('q0'),  // "At what age can children start swimming?"
            answer: t('a0'),    // "Children can start swimming from 6 months old."
        },
        {
            question: t('q1'),  // "What do I need for a swimming or exercise class?"
            answer: t('a1'),    // "For Little Swim classes, you need swimwear for yourself and special swimwear for the baby (neoprene swimwear). For Little Ninjas exercise classes, comfortable clothing for you and your child is sufficient."
        },
        {
            question: t('q2'),  // "How long does one class last?"
            answer: t('a2'),    // "Little Swim classes during the week last 30 minutes, and weekend classes last 40 minutes. (Both parents can attend the class), which is an ideal duration for young children to enjoy the water without excessive fatigue. Little Ninjas exercise classes last approximately 40 minutes."
        },
        {
            question: t('q3'),  // "Are the courses safe for my child?"
            answer: t('a3'),    // "Yes, safety is our priority. Our instructors are qualified, and the pools meet all safety standards. The water is regularly checked and maintained at an optimal temperature for young children."
        },
        {
            question: t('q4'),  // "Is the presence of a parent necessary during the class?"
            answer: t('a4'),    // "Yes, active participation of parents is essential. Your presence provides your child with a sense of security and supports shared experiences. Little Ninjas exercises are designed for parents to be actively involved in their child's activities. Parental presence is not required for SuperNinjas classes (ages 3 to 5)."
        },
        {
            question: t('q5'),  // "What if my child doesn't like water?"
            answer: t('a5'),    // "Some children need more time to get used to water. Our instructors are experienced with various reactions from children and will help you and your child gradually adapt and enjoy water activities."
        },
        {
            question: t('q6'),  // "How can I sign up for a course?"
            answer: t('a6'),    // "Fill out the registration form, select your preferred course, lesson, day, and time. After electronic reservation, you will receive a confirmation with further details."
        },
        {
            question: t('q7'),  // "What if I can't attend a class?"
            answer: t('a7'),    // "If you cannot attend a class, please send us an SMS by 5:00 PM the day before the class at 774 300 181 (please include your child's name, date, and time of the class). If the class is not excused in time, it will be forfeited. Please excuse the class even if you know you've missed the deadline, so we don’t wait unnecessarily. You can make up 3 classes, but only if they are properly excused. You will have one month after the course ends to make up the missed classes. If you continue in another course, the month for make-up will start only after the last course ends."
        },
        {
            question: t('q8'),  // "Can I observe a class before deciding to enroll?"
            answer: t('a8'),    // "Yes, we are happy to welcome you to a trial class. Please contact us in advance so we can arrange your visit."
        },
        {
            question: t('q9'),  // "What are the course fees?"
            answer: t('a9'),    // "Course fees vary depending on the length and type of the course. You can find the current price on the specific course card in the Lessons section. If you have any questions about the prices, please feel free to contact us."
        }
    ];





    const backgrounds = [
        bg1,
        bg2,
        bg3,
        bg4,
        bg5,
        bg6,
        bg7,
        bg8,
    ];

    const littleSwimCourses = [
        { title: t('SwT0'), price: t('SwPr0'), infoPrice: t('SwPrI0'), place:["Čapkárna, Karviná"], description: t('SwDesc0') },
        { title: t('SwT1'), price: t('SwPr1'), infoPrice: t('SwPrI1'), place:["Čapkárna, AD Landek, Karviná"], description: t('SwDesc1') },
        { title: t('SwT2'), price: t('SwPr2'), infoPrice: t('SwPrI2'), place:["AD Landek"], description: t('SwDesc2')},
        { title: t('SwT3'), price: t('SwPr3'), infoPrice: t('SwPrI3'), place:[t('SwLoc')], description: t('SwDesc3') },
    ];

    const littleNinjasCourses = [
        { title: t('NiT0'), price: t('NiPr0'), infoPrice:"", place:["AD Landek"], description: t('NiDesc0') },
        { title: t('NiT1'), price: t('NiPr1'), infoPrice:"", place:["AD Landek"], description: t('NiDesc1') },
        { title: t('NiT2'), price: t('NiPr2'), infoPrice:"", place:["AD Landek"], description: t('NiDesc2') },
        { title: t('NiT3'), price: t('NiPr3'), infoPrice:"", place:["AD Landek"], description: t('NiDesc3') },
    ];

    return (
        <div>
            <section id="lessons" className="container mx-auto py-8 px-6 min-h-screen pt-28">
                <div id="littleswim">
                    <h2 className="text-blue-900 font-gluten text-5xl relative h-auto w-full pb-6 text-center dark:text-white">
                        LittleSwim
                    </h2>
                    <Slide direction="left" triggerOnce>
                        <div
                            className="bg-sky-100 dark:bg-gray-800 rounded-lg shadow-lg p-6 my-6 mx-auto max-w-4xl font-varela dark:text-white">
                            <div className="relative">
                                <div className="text-wrapper-left flex flex-col items-center md:block">
                                    <img
                                        src={hippo2}
                                        alt="Hippo"
                                        className="w-48 h-48 mb-4 md:float-right md:mr-4 md:mb-4 md:w-48 md:h-48"
                                    />
                                    <p className="text-lg text-justify">
                                        {t('SwHeadDesc')}
                                    </p>
                                </div>
                                <h2 className="text-center font-varela text-xl pt-6 pb-4 text-blue-800 uppercase dark:text-blue-500">
                                    {t('SwWhyOue')}
                                </h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 font-varela">
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-blue-500 text-2xl mr-2"/>
                                        <span>{t('SwPt0')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-blue-500 text-2xl mr-2"/>
                                        <span>{t('SwPt1')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-blue-500 text-2xl mr-2"/>
                                        <span>{t('SwPt2')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-blue-500 text-2xl mr-2"/>
                                        <span>{t('SwPt3')}</span>
                                    </div>
                                </div>
                                <hr className="border-t-1 border-gray-400 mt-10"/>

                                <div className="pt-10 flex justify-center">
                                    <button
                                        onClick={openSwimPdf}
                                        className="flex flex-row bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 text-center uppercase font-oswald"
                                    >
                                        <FaFilePdf className="text-white text-md mr-2 mt-1"/>{t('BasicRulesBtn')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <Title title={t('OfferButton')} titleColor="text-blue-900" from="from-sky-200" to="to-white"/>
                    <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 pt-8 gap-5">
                    {littleSwimCourses.map((course, index) => (
                            <Zoom key={index} triggerOnce={true}>
                                <div className="flip-card transition-all my-5" onClick={() => handleFlip(index)}>
                                    <div className={`flip-card-inner ${flipped[index] ? "flipped" : ""}`}>
                                        <div className="flip-card-front flex flex-col justify-center items-center"
                                             style={{backgroundImage: `url(${backgrounds[index]})`}}>
                                            <h3 className="font-bold font-oswald text-2xl leading-8 p-4 bg-black bg-opacity-50 rounded-lg text-center shadow-lg">
                                                {course.title}
                                            </h3>
                                        </div>
                                        <div className="flip-card-back flex flex-col justify-between items-center p-4">
                                            <div className="w-full">
                                                <h3 className="font-bold font-oswald text-2xl leading-8 text-center mb-1">
                                                    {course.title}
                                                </h3>
                                            </div>
                                            <div className="flex-grow">
                                                <p className="h-44 text-center font-varela text-md my-3">
                                                    {course.description}
                                                </p>
                                            </div>
                                            <div className="w-full text-center items-center pt-5">
                                                <p className="font-extrabold text-blue-800 font-oswald text-md mt-4 hover:underline uppercase">
                                                    <a href="#locations">{t('SwPlaceTitle')}</a>
                                                </p>
                                                {course.place.map((place, placeIndex) => (
                                                    <p className="font-extrabold font-oswald text-sm"
                                                       key={placeIndex}>{place}</p>
                                                ))}
                                            </div>
                                            <div className="w-full">
                                                <p className="font-bold font-oswald text-xl text-blue-800 text-center mt-4">
                                                    {t('PriceTitle')}: {course.price}
                                                </p>
                                                <p className="font-bold font-oswald text-xs text-center">
                                                    {course.infoPrice}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Zoom>

                        ))}
                    </div>
                    <div className="flex justify-center items-center">
                        <a href="#reservation"
                           className="uppercase font-bold mt-10 mb-10 bg-white text-blue-800 px-6 py-3 rounded-lg border border-transparent shadow-md hover:shadow-lg transform hover:scale-105 transition-transform transition-shadow dark:bg-gray-800 dark:text-blue-400 dark:hover:text-white">
                            {t('WantOffer')}
                        </a>
                    </div>
                    <Title title="" titleColor="text-blue-900" from="from-sky-200" to="to-white"/>
                </div>

                <div id="littleninjas" className="pt-24">
                    <h2 className="text-yellow-500 font-gluten text-5xl relative h-auto w-full pb-6 text-center dark:text-white">
                        LittleNinjas
                    </h2>
                    <Slide direction="right" triggerOnce>
                        <div
                            className="bg-amber-100 dark:bg-gray-800 rounded-lg shadow-lg p-6 my-6 mx-auto max-w-4xl font-varela dark:text-white">
                            <div className="relative">
                                <div className="text-wrapper-right flex flex-col items-center md:block">
                                    <img
                                        src={ninja}
                                        alt="Ninja"
                                        className="w-48 h-48 mb-4 md:float-right md:ml-4 md:mb-4"
                                    />
                                    <p className="text-lg text-justify">
                                        {t('NiHeadDesc')}
                                    </p>
                                </div>
                                <h2 className="text-center font-varela text-xl pt-6 pb-4 text-yellow-700 uppercase dark:text-yellow-600">
                                    {t('NiWhyOue')}
                                </h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 font-varela">
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-yellow-500 text-2xl mr-2"/>
                                        <span>{t('NiPt0')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-yellow-500 text-2xl mr-2"/>
                                        <span>{t('NiPt1')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-yellow-500 text-2xl mr-2"/>
                                        <span>{t('NiPt2')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-yellow-500 text-2xl mr-2"/>
                                        <span>{t('NiPt3')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-yellow-500 text-2xl mr-2"/>
                                        <span>{t('NiPt4')}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <FaCheckCircle className="text-yellow-500 text-2xl mr-2"/>
                                        <span>{t('NiPt5')}</span>
                                    </div>
                                </div>
                                <hr className="border-t-1 border-gray-400 mt-10"/>

                                <div className="pt-10 flex justify-center">
                                    <button
                                        onClick={openNinjasPdf}
                                        className="flex flex-row bg-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-yellow-400 text-center uppercase font-oswald"
                                    >
                                        <FaFilePdf className="text-white text-md mr-2 mt-1"/>{t('BasicRulesBtn')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <Title title={t('OfferButton')} titleColor="text-yellow-600" from="from-yellow-100" to="to-white"/>
                    <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 pt-8 gap-5">
                        {littleNinjasCourses.map((course, index) => (
                            <Zoom key={index} triggerOnce={true}>
                                <div className="flip-card transition-all my-5" onClick={() => handleFlip(index + 4)}>
                                <div className={`flip-card-inner ${flipped[index + 4] ? "flipped" : ""}`}>
                                        <div className="flip-card-front flex flex-col justify-center items-center"
                                             style={{backgroundImage: `url(${backgrounds[index + 4]})`}}>
                                            <h3 className="font-bold font-oswald text-2xl leading-8 p-4 bg-black bg-opacity-50 rounded-lg text-center shadow-lg">
                                                {course.title}
                                            </h3>
                                        </div>
                                        <div
                                            className="flip-card-back-ninja flex flex-col justify-between items-center p-4">
                                            <div className="w-full">
                                                <h3 className="font-bold font-oswald text-2xl leading-8 text-center mb-1">
                                                    {course.title}
                                                </h3>
                                            </div>
                                            <div className="flex-grow">
                                                <p className="h-44 text-center font-varela text-md my-3">
                                                    {course.description}
                                                </p>
                                            </div>
                                            <div className="w-full text-center items-center pt-5">
                                                <p className="font-extrabold text-yellow-600 hover:underline font-oswald text-md mt-4 uppercase">
                                                    <a href="#locations">{t('NiPlaceTitle')}</a>
                                                </p>
                                                {course.place.map((place, placeIndex) => (
                                                    <p className="font-extrabold font-oswald text-sm"
                                                       key={placeIndex}>{place}</p>
                                                ))}
                                            </div>
                                            <div className="w-full">
                                                <p className="font-bold font-oswald text-xl text-yellow-600 text-center mt-4">
                                                    {t('PriceTitle')}: {course.price}
                                                </p>
                                                <p className="font-bold font-oswald text-xs text-center">
                                                    {course.infoPrice}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Zoom>

                        ))}
                    </div>
                    <div className="flex justify-center items-center">
                        <a href="#reservation"
                           className="uppercase font-bold mt-10 mb-10 bg-white text-yellow-600 px-6 py-3 rounded-lg border border-transparent shadow-md hover:shadow-lg transform hover:scale-105 transition-transform transition-shadow dark:bg-gray-800 dark:text-yellow-500 dark:hover:text-white">
                            {t('WantOffer')}
                        </a>
                    </div>
                    <Title title="" titleColor="text-yellow-500" from="from-yellow-100" to="to-white"/>
                    <div className="rounded-lg shadow-lg p-4 mt-20 my-6 mx-auto max-w-xl dark:bg-gray-800">
                        <FAQ faqs={faqs}/>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Services;
